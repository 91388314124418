import {
    CodeDetectorProtection,
    CustomModeratorProtection,
    DataPrivacyGuidelinesProtection,
    JailbreakDetectorProtection,
    LanguageDetectorProtection,
    ModeratorProtection,
    PromptHardeningProtection,
    PromptInjectionEngineProtection,
    PromptLeakDetectorProtection,
    RegexProtection,
    SanitizerModelProtection,
    SecretsProtection,
    SensitiveDataProtection,
    SentimentProtection,
    TokenLimitationProtection,
    TokenRateLimitProtection,
    TopicsDetectorProtection,
    UrlsDetectorProtection
} from '..';

import { AllProtectionKeys, IProtectionProps } from './protections_types';

export const ProtectionKeyToComponentMap: Record<typeof AllProtectionKeys[number], React.FC<IProtectionProps>> = {
    'Data Privacy Guidelines': DataPrivacyGuidelinesProtection,
    'Prompt Injection Engine': PromptInjectionEngineProtection,
    'Sensitive Data': SensitiveDataProtection,
    'Topics Detector': TopicsDetectorProtection,
    'Code Detector': CodeDetectorProtection,
    'Jailbreak Detector': JailbreakDetectorProtection,
    'Language Detector': LanguageDetectorProtection,
    'Moderator': ModeratorProtection,
    'Prompt Hardening': PromptHardeningProtection,
    'Prompt Leak Detector': PromptLeakDetectorProtection,
    'Regex': RegexProtection,
    'Sanitizer Model': () => null, //SanitizerProtection// NOTICE: (Currently Lior and Benji asked to hide it.)
    'Secrets': SecretsProtection,
    'Sentiment': SentimentProtection,
    'Strict Format': () => null, //StrictFormatProtection// NOTICE: (Currently Lior and Benji asked to hide it.)
    'Token Limitation': TokenLimitationProtection,
    'Token Rate Limit': TokenRateLimitProtection,
    'URLs Detector': UrlsDetectorProtection,
    'Custom Moderator': CustomModeratorProtection,
};
