import { AllProtectionKeys } from "./protections_types";
import {ColorThemeKeys} from "../../../../styles";

export const protectionKeysColorMap: Record<typeof AllProtectionKeys[number], string> = {
    Regex: '#BC8CFA',
    Secrets: '#A569E7',
    Moderator: '#CFD7FF',
    Sentiment: '#677FFF',
    "Code Detector": '#A3B1FF',
    "URLs Detector": '#F37787',
    'Sensitive Data': '#6947CF',
    'Topics Detector': '#9EE0FA',
    'Prompt Hardening': '#3FCB95',
    'Token Limitation': '#D5ADFD',
    'Token Rate Limit': '#67B8FF',
    'Language Detector': '#7CF5FC',
    'Jailbreak Detector': '#7BDAB7',
    'Prompt Injection Engine': '#FFA0AD',
    'Data Privacy Guidelines': '#55C6FF',
    'Prompt Leak Detector': '#A2F4D6',
    "Strict Format": '#EC3177',
    'Sanitizer Model': '#FCDB1F',
    'Custom Moderator': '#FFD15C',
}

export const protectionKeysThemeColorNameMap: Record<typeof AllProtectionKeys[number], ColorThemeKeys> = {
    Regex: 'purple-40',
    Secrets: 'purple-60',
    Moderator: 'blue-20',
    Sentiment: 'blue-50',
    "Code Detector": 'blue-30',
    "URLs Detector": 'red-30',
    'Sensitive Data': 'purple-80',
    'Topics Detector': 'aqua-30',
    'Prompt Hardening': 'green-50',
    'Token Limitation': 'purple-30',
    'Token Rate Limit': 'blue-40',
    'Language Detector': 'aqua-20',
    'Jailbreak Detector': 'green-40',
    'Prompt Injection Engine': 'red-20',
    'Data Privacy Guidelines': 'aqua-50',
    'Prompt Leak Detector': 'green-20',
    "Strict Format": 'red-50',
    'Sanitizer Model': 'yellow-50',
    'Custom Moderator': 'orange-30',
}