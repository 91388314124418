/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Tab, TabProps, Skeleton } from "@mui/material";
import { TextStyle } from "../Text/Text.css";
import Text from "../Text/Text";
import Icon from "../Icon/Icon";
import { css } from "@emotion/react";
import { IconNames } from "../Icon/Icon";
import {ReactNode} from "react";

const variantTheme = {
    primary: {
        default: {
            container: {
                background: 'var(--color-transparent)',
            },
            icon: {
                fill: 'var(--color-black)',
            },
            iconLabel: {
                color: 'var(--color-black)',
            },
            text: {
                color: 'var(--color-black)',
            },
            indicator: {
                background: 'var(--color-transparent)',
            }
        },
        hover: {
            container: {
                background: 'var(--color-black-20)',
            },
            icon: {
                fill: 'var(--color-black)',
            },
            iconLabel: {
                color: 'var(--color-black)',
            },
            text: {
                color: 'var(--color-black)',
            },
            indicator: {
                background: 'var(--color-transparent)',
            }
        },
        active: {
            container: {
                background: 'var(--color-transparent)',
            },
            icon: {
                fill: 'var(--color-black)',
            },
            iconLabel: {
                color: 'var(--color-black)',
            },
            text: {
                color: 'var(--color-black)',
            },
            indicator: {
                background: 'var(--color-blue-20)',
            }
        },
        disabled: {
            container: {
                background: 'var(--color-transparent)',
            },
            icon: {
                fill: 'var(--color-black-40)',
            },
            iconLabel: {
                color: 'var(--color-black-40)',
            },
            text: {
                color: 'var(--color-black-40)',
            },
            indicator: {
                background: 'var(--color-black-40)',
            }
        },
        selected: {
            container: {
                background: 'var(--color-transparent)',
            },
            icon: {
                fill: 'var(--color-black)',
            },
            iconLabel: {
                color: 'var(--color-black)',
            },
            text: {
                color: 'var(--color-black)',
            },
            indicator: {
                background: 'var(--color-black)',
            }
        }
    },
    secondary: {
        default: {
            container: {
                background: 'var(--color-transparent)',
            },
            icon: {
                fill: 'var(--color-purple-80)',
            },
            iconLabel: {
                color: 'var(--color-purple-80)',
            },
            text: {
                color: 'var(--color-black-70)',
            },
            indicator: {
                background: 'var(--color-transparent)',
            }
        },
        hover: {
            container: {
                background: 'var(--color-black-20)',
            },
            icon: {
                fill: 'var(--color-purple-80)',
            },
            iconLabel: {
                color: 'var(--color-purple-80)',
            },
            text: {
                color: 'var(--color-black-70)',
            },
            indicator: {
                background: 'var(--color-transparent)',
            }
        },
        active: {
            container: {
                background: 'var(--color-transparent)',
            },
            icon: {
                fill: 'var(--color-purple-80)',
            },
            iconLabel: {
                color: 'var(--color-purple-80)',
            },
            text: {
                color: 'var(--color-black)',
            },
            indicator: {
                background: 'var(--color-blue-20)',
            }
        },
        disabled: {
            container: {
                background: 'var(--color-transparent)',
            },
            icon: {
                fill: 'var(--color-black-50)',
            },
            iconLabel: {
                color: 'var(--color-black-50)',
            },
            text: {
                color: 'var(--color-black-40)',
            },
            indicator: {
                background: 'var(--color-black-40)',
            }
        },
        selected: {
            container: {
                background: 'var(--color-transparent)',
            },
            icon: {
                fill: 'var(--color-purple-80)',
            },
            iconLabel: {
                color: 'var(--color-purple-80)',
            },
            text: {
                color: 'var(--color-purple-80)',
            },
            indicator: {
                background: 'var(--color-purple-80)',
            }
        }
    }
}

const PSTab = styled(({ isLoading, number, label, iconName, iconLabel, variant = 'primary', endAdornment, ...props }: TabProps & {
    isLoading?: boolean;
    number?: number;
    to?: string;
    iconName?: IconNames,
    iconLabel?: string,
    withoutIndicator?: boolean,
    isSelected?: boolean,
    variant?: keyof typeof variantTheme,
    altOnClick?: Function,
    endAdornment?: ReactNode,
}) => (
    <>
        {isLoading && <Skeleton
            className="loading"
            variant="text"
            width={typeof label === "string" ? label.length * 7.5 : 100}
            height={30}
            sx={{
                margin: '12px 15px'
            }}
        />}
        {!isLoading &&
            <Tab
                disableRipple
                {...props}
                className={props.className + ' ' + (props.isSelected ? 'isSelected' : '')}
                label={
                    <div css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        height: 100%;`
                    }>
                        <div className='tab-label'>
                            {number !== undefined && <Text className="number-text" variant="bold" color="black-40">{number}</Text>}
                            <Text variant="small" className='tab-label-text'>
                                {label}
                            </Text>
                            {iconName && <div className='tab-label-icon'>
                                <Icon iconName={iconName} iconSize={15} color='purple-80' />
                                {iconLabel && <Text variant="small" className='tab-label-icon-text' color='purple-80'>{iconLabel}</Text>}
                            </div>}
                        </div>
                        {endAdornment && endAdornment}
                    </div>
                }
            />
        }
    </>
))`
    padding: 0 20px;
    min-height: 50px;
    min-width: fit-content;
    text-transform: none;
    opacity: 1;
    ${TextStyle.text}
    background: ${props => variantTheme[props.variant || 'primary'].default.container.background};
    color: ${props => variantTheme[props.variant || 'primary'].default.text.color};
    position: relative;

    .separator & {
        &:not(:first-of-type):before {
            content: "";
            height: 16px;
            width: 1px;
            background: var(--color-black-30);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &:hover {
        color: ${props => variantTheme[props.variant || 'primary'].hover.text.color};
        background: ${props => variantTheme[props.variant || 'primary'].hover.container.background};
        
        .number-text {
            color: ${props => variantTheme[props.variant || 'primary'].hover.text.color};
        }

        .tab-label-icon {
            .tab-label-icon-text {
                color: ${props => variantTheme[props.variant || 'primary'].hover.iconLabel.color};
            }
            .icon {
                fill: ${props => variantTheme[props.variant || 'primary'].hover.icon.fill};
            }
        }
    }
    
    .tab-label {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        height: 100%;
        color: ${props => variantTheme[props.variant || 'primary'].default.text.color};

        .tab-label-text:before {
            content: "${props => typeof props.label === 'string' ? props.label : ''}";
            display: block;
            font-weight: 700;
            height: 0;
            opacity: 0;
        }

        .tab-label-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            .tab-label-icon-text {
                color: ${props => variantTheme[props.variant || 'primary'].default.iconLabel.color};
            }
            .icon {
                fill: ${props => variantTheme[props.variant || 'primary'].default.icon.fill};
            }
        }

        &::before {
            content: "";
            width: 100%;
            height: 0;
            background: ${props => variantTheme[props.variant || 'primary'].default.indicator.background};
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    &:active {
        color: ${props => variantTheme[props.variant || 'primary'].active.text.color};
        background: ${props => variantTheme[props.variant || 'primary'].active.container.background};

        .tab-label-text {
                font-weight: 700;
        }

        .number-text {
            color: ${props => variantTheme[props.variant || 'primary'].active.text.color};
        }
        .tab-label {
            ${props => !props.withoutIndicator && `
                &::before  {
                    background: ${variantTheme[props.variant || 'primary'].active.indicator.background};
                    height: 4px;
                }
            `}
        }
    }

    &:focus-visible {
        outline: 2px solid var(--color-blue-20);
        outline-offset: -2px;
    }

    &.Mui-selected, &.isSelected {
        border: 0;
        color: ${props => variantTheme[props.variant || 'primary'].selected.text.color};
        background: ${props => variantTheme[props.variant || 'primary'].selected.container.background};

        .number-text {
            color: ${props => variantTheme[props.variant || 'primary'].selected.text.color};
        }

        .tab-label-icon {
            .tab-label-icon-text {
                color: ${props => variantTheme[props.variant || 'primary'].selected.iconLabel.color};
            }
            .icon {
                fill: ${props => variantTheme[props.variant || 'primary'].selected.icon.fill};
            }
        }

        .tab-label {
            .tab-label-text {
                font-weight: 700;
            }
            ${props => !props.withoutIndicator && `
                &::before {
                    height: 4px;
                    background: ${variantTheme[props.variant || 'primary'].selected.indicator.background};
                }
            `}
        }

        &:hover {
            background: ${props => variantTheme[props.variant || 'primary'].selected.container.background};
            cursor: default;
        }
    }

    &.Mui-disabled {
        color: ${props => variantTheme[props.variant || 'primary'].disabled.text.color};
        background: ${props => variantTheme[props.variant || 'primary'].disabled.container.background};

        .tab-label-icon {
            .tab-label-icon-text {
                color: ${props => variantTheme[props.variant || 'primary'].disabled.iconLabel.color};
            }
            .icon {
                fill: ${props => variantTheme[props.variant || 'primary'].disabled.icon.fill};
            }
        }

        &.Mui-selected .tab-label,
        &.isSelected .tab-label {
            &::before {
                background: ${props => variantTheme[props.variant || 'primary'].disabled.indicator.background};
            }
        }
    }
`;

export default PSTab;