import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { FronteggProvider } from '@frontegg/react';
import RootAuthWrapper from './routes-new/RootAuthWrapper';
import { DateContextProvider } from './contexts';
import { useFronteggConfig } from './hooks';
import { PSLoadingScreen } from './ui-kit';
import InitialLoader from './routes-new/InitialLoader';
import { useLocalStorage } from 'react-use';

type IProps = {}

const Dashboard = lazy(() => import('./routes/Dashboard'));
const ManagePolicies = lazy(() => import('./routes/ManagePolicies/index'));
const ManageApplications = lazy(() => import('./routes/ManageApplications/index'));
const AdvancedRules = lazy(() => import('./routes/AdvancedRules/AdvancedRules'));
const ManageIntegration = lazy(() => import('./routes/ManageIntegration/index'));
const DashboardsPage = lazy(() => import('./routes-new/DashboardsPage/DashboardsPage'));
const ExtensionPage = lazy(() => import('./routes-new/ExtensionPage/ExtensionPage'));
const Root = lazy(() => import('./routes-new/Root'));
const AuditPage = lazy(() => import('./routes-new/AuditLogsPage/AuditPage'));
const AlertsLogsPage = lazy(() => import('./routes-new/AlertsLogsPage/AlertsLogsPage'));
const Error404 = lazy(() => import('./routes-new/ErrorPages/Error404'));
const Developers = lazy(() => import('./routes-new/Manage/Developers/Developers'));
const DevelopersDeployments = lazy(() => import('./routes-new/Manage/Developers/DevelopersDeployments'));
const DevelopersPolicies = lazy(() => import('./routes-new/Manage/Developers/DevelopersPolicies'));
const Employees = lazy(() => import('./routes-new/Manage/Employees/Employees'));
const EmployeesDeployment = lazy(() => import('./routes-new/Manage/Employees/EmployeesDeployments'));
const EmployeesExtensionSettings = lazy(() => import('./routes-new/Manage/Employees/EmployeesExtensionSettings'));
const EmployeesGenAiApplications = lazy(() => import('./routes-new/Manage/Employees/EmployeesManageGenAiApplications'));
const EmployeesPolicies = lazy(() => import('./routes-new/Manage/Employees/EmployeesPolicies'));
const HomegrownApplications = lazy(() => import('./routes-new/Manage/HomegrownApplications/HomegrownApplications'));
const HomegrownApplicationsDeployments = lazy(() => import('./routes-new/Manage/HomegrownApplications/HomegrownApplicationsDeployments'));
const HomegrownApplicationsPolicies = lazy(() => import('./routes-new/Manage/HomegrownApplications/HomegrownApplicationsPolicies'));
const EmployeesDashboard = lazy(() => import('./routes-new/DashboardsPage/Employees/Employees'));
const HomegrownAppsDashboard = lazy(() => import('./routes-new/DashboardsPage/HomegrownApps/HomegrownApps'));
const HomegrownAppsContent = lazy(() => import('./routes-new/DashboardsPage/HomegrownApps/HomegrownAppsContent'));

// const UsersPage = lazy(() => import('./routes-new/UsersPage/UsersPage'));

export type TDashboardPath = 'employees' | 'employees-beta' | 'developers' | 'homegrown-apps';

const Router: React.FC<IProps> = (props) => {
    const { contextOptions, isLoading } = useFronteggConfig();

    const [lastDashboardPath, setLastDashboardPath] = useLocalStorage<TDashboardPath>('lastDashboardPath', 'employees');

    if (isLoading) return <PSLoadingScreen />;

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <FronteggProvider
                    contextOptions={contextOptions}
                    hostedLoginBox={true}
                >
                    <RootAuthWrapper>
                        <InitialLoader>
                            <Suspense fallback={<PSLoadingScreen />}>
                                <Root />
                            </Suspense>
                        </InitialLoader>
                    </RootAuthWrapper>
                </FronteggProvider>
            ),
            errorElement: <Suspense fallback={<PSLoadingScreen />}><Error404 /></Suspense>,
            children: [
                { path: '/', Component: () => <Navigate to="/activity-monitor" relative='path' /> },
                {
                    path: "/dashboards",
                    element: (
                        <Suspense fallback={<PSLoadingScreen />}>
                            <DashboardsPage setLastDashboardPath={setLastDashboardPath} />
                        </Suspense>
                    ),
                    handle: {
                        breadcrumb: 'Dashboards',
                        shouldDisablePadding: true,
                    },
                    children: [
                        { 
                            path: "", 
                            Component: () => <Navigate to={lastDashboardPath || 'employees'} /> 
                        },
                        {
                            path: "employees",
                            element: <Suspense fallback={<PSLoadingScreen />}><Dashboard dashboardType="ps-dash-extension" /></Suspense>,
                        },
                        {
                            path: "employees-beta",
                            element: <Suspense fallback={<PSLoadingScreen />}><DateContextProvider page='dashboard'><EmployeesDashboard/></DateContextProvider></Suspense>,
                            handle: { shouldDisablePadding: true },
                        },
                        {
                            path: "homegrown-apps-new",
                            element: <Suspense fallback={<PSLoadingScreen />}><DateContextProvider page='dashboard'><HomegrownAppsDashboard/></DateContextProvider></Suspense>,
                            handle: { shouldDisablePadding: true },
                            children: [
                                {
                                    path: ":connectorName",
                                    element: <Suspense fallback={<PSLoadingScreen />}><HomegrownAppsContent/></Suspense>,
                                    handle: { shouldDisablePadding: true }
                                },
                            ]
                        },

                        {
                            path: 'developers',
                            element: <Suspense fallback={<PSLoadingScreen />}><Dashboard dashboardType="ps-dash-code-assistant" /></Suspense>,
                        },
                        {
                            path: 'homegrown-apps',
                            element: <Suspense fallback={<PSLoadingScreen />}><Dashboard dashboardType="ps-dash" /></Suspense>,
                        }
                    ]
                },
                {
                    path: "/extension-new",
                    element: <DateContextProvider page='dashboard'><Suspense fallback={<PSLoadingScreen />}><ExtensionPage /></Suspense></DateContextProvider>,
                    handle: { breadcrumb: 'Extension New (Beta)' },
                },
                {
                    index: true,
                    path: "/activity-monitor",
                    element: <DateContextProvider page='logs'><Suspense fallback={<PSLoadingScreen />}><AlertsLogsPage /></Suspense></DateContextProvider>,
                    handle: { breadcrumb: 'Activity Monitor' },
                },
                {
                    path: "/alerts",
                    element: <Suspense fallback={<PSLoadingScreen />}><Dashboard dashboardType="ps-alerts" /></Suspense>,
                    handle: { breadcrumb: 'Alerts' },
                },
                // {
                //     path: "/users",
                //     element: <Suspense fallback={<PSLoadingScreen />}><UsersPage /></Suspense>,
                //     handle: { breadcrumb: 'Users' },
                // },
                {
                    path: "/logs",
                    element: <Suspense fallback={<PSLoadingScreen />}><Dashboard dashboardType="ps-logs" /></Suspense>,
                    handle: { breadcrumb: 'Logs' },
                },
                {
                    path: "/audit",
                    element: <DateContextProvider page='audit'><Suspense fallback={<PSLoadingScreen />}><AuditPage /></Suspense></DateContextProvider>,
                    handle: { breadcrumb: 'Audit', showDateRange: true },
                },
                {
                    path: "/manage/policies",
                    element: <Suspense fallback={<PSLoadingScreen />}><ManagePolicies /></Suspense>,
                    handle: { breadcrumb: 'Manage Policies' },
                },
                {
                    path: "/manage/connectors",
                    element: <Suspense fallback={<PSLoadingScreen />}><ManageApplications /></Suspense>,
                    handle: { breadcrumb: 'Manage Connectors' },
                },
                {
                    path: "/manage/connectors/:applicationName/rules",
                    element: <Suspense fallback={<PSLoadingScreen />}><AdvancedRules /></Suspense>,
                    handle: { breadcrumb: 'Connectors Rules' },
                },
                {
                    path: "/manage/integrations",
                    element: <Suspense fallback={<PSLoadingScreen />}><ManageIntegration /></Suspense>,
                    handle: { breadcrumb: 'Manage Integrations' },
                },
                {
                    path: "/homegrown-applications/manage/:connectorName",
                    element: <Suspense fallback={<PSLoadingScreen />}><HomegrownApplications /></Suspense>,
                    handle: {
                        breadcrumb: 'Manage Homegrown Applications',
                        shouldDisablePadding: true,
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'homegrown-applications',
                            connectorType: 'REGULAR'
                        }
                    },
                    children: [
                        {
                            path: "policies",
                            element: <Suspense fallback={<PSLoadingScreen />}><HomegrownApplicationsPolicies /></Suspense>,
                        },
                        {
                            path: 'deployment',
                            element: <Suspense fallback={<PSLoadingScreen />}><HomegrownApplicationsDeployments /></Suspense>,
                        }
                    ]
                },
                {
                    path: "/employees/manage/:connectorName",
                    element: <Suspense fallback={<PSLoadingScreen />}><Employees /></Suspense>,
                    handle: {
                        breadcrumb: 'Manage Employees',
                        shouldDisablePadding: true,
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'employees',
                            connectorType: 'EXTENSION'
                        }
                    },
                    children: [
                        { path: "", Component: () => <Navigate to="policies" /> },
                        {
                            path: "policies",
                            element: <Suspense fallback={<PSLoadingScreen />}><EmployeesPolicies /></Suspense>,
                        },
                        {
                            path: 'deployment',
                            element: <Suspense fallback={<PSLoadingScreen />}><EmployeesDeployment /></Suspense>,
                        },
                        {
                            path: 'extension-settings',
                            element: <Suspense fallback={<PSLoadingScreen />}><EmployeesExtensionSettings /></Suspense>,
                        },
                        {
                            path: 'manage-gen-ai-applications',
                            element: <Suspense fallback={<PSLoadingScreen />}><EmployeesGenAiApplications /></Suspense>,
                        }
                    ]
                },
                {
                    path: "/developers/manage/:connectorName",
                    element: <Suspense fallback={<PSLoadingScreen />}><Developers /></Suspense>,
                    handle: {
                        breadcrumb: 'Manage Developers',
                        shouldDisablePadding: true,
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'developers',
                            connectorType: 'CODE_ASSISTANT'
                        }
                    },
                    children: [
                        { path: "", Component: () => <Navigate to="policies" /> },
                        {
                            path: "policies",
                            element: <Suspense fallback={<PSLoadingScreen />}><DevelopersPolicies /></Suspense>,
                        },
                        {
                            path: 'deployment',
                            element: <Suspense fallback={<PSLoadingScreen />}><DevelopersDeployments /></Suspense>,
                        }
                    ]
                },
                {
                    path: "*",
                    element: <Suspense fallback={<PSLoadingScreen />}><Error404 /></Suspense>,
                    handle: { breadcrumb: 'Page Not Found!' },
                }
            ],
        },
    ]);

    return (
        <RouterProvider router={router} />
    );
}

export default Router;