import React, { createContext, useState, useMemo, useEffect } from 'react';
import { useGraphQL } from "../hooks";
import { PSLoadingScreen } from "../ui-kit";
import { graphql } from "../gql";
import { Application } from "../gql/generated/graphql";
import { useLocalStorage } from "react-use";

const SCREEN_WIDTH_TO_MINIMIZE_GAP = 1440;

const queryAllConnectorsAndIntegrations = graphql(`
    query queryAllConnectorsAndIntegrations {
        applications {
            name
            applicationType
            id
        }
        integration {
            isEnabled
            type
        }
    }
`);

type DashboardContextType = {
    isShowErrorForAllPage: boolean;
    setIsShowErrorForAllPage: (value: boolean) => void;

    violationsCount: number | undefined;
    setViolationsCount: (value: number) => void;

    promptsCount: number | undefined;
    setPromptsCount: (value: number) => void;

    installedExtensionsCount: number | undefined;
    setInstalledExtensionsCount: (value: number) => void;

    tableHeight: number | undefined;
    setTableHeight: (value: number) => void;

    isIDPConnected: boolean;
    isLoggingDisabled: boolean;

    employeesConnectorName: string;
    developersConnectorName: string;
    homegrownAppConnectors: Partial<Application>[];

    SCREEN_WIDTH_TO_MINIMIZE_GAP: number;

    lastHomegrownApp: string | undefined;
    setLastHomegrownApp: (value: string) => void;

    currentHGAID: string | undefined;
    setCurrentHGAID: (value: string) => void;
}

export const DashboardsContext = createContext<DashboardContextType | null>(null);

interface Connectors {
    employeesConnectorName: string;
    developersConnectorName: string;
    homegrownAppConnectors: Partial<Application>[],
}

type IProps = {
    children: React.ReactNode;
};

export const DashboardsContextProvider: React.FC<IProps> = (props) => {
    const {children} = props;

    const [violationsCount, setViolationsCount] = useState<number>();
    const [promptsCount, setPromptsCount] = useState<number>();
    const [installedExtensionsCount, setInstalledExtensionsCount] = useState<number>();
    const [tableHeight, setTableHeight] = useState<number>();

    const [isLoggingDisabled, setIsLoggingDisabled] = useState(false);
    const [isIDPConnected, setIsIDPConnected] = useState(false);

    const [isShowErrorForAllPage, setIsShowErrorForAllPage] = useState(false);

    const { data, isLoading } = useGraphQL({
        document: queryAllConnectorsAndIntegrations,
        onSuccess: (data) => {
            data?.integration?.forEach((integration) => {
                if (integration?.type === "OPENSEARCH") setIsLoggingDisabled(!integration.isEnabled);

                const isIDPIntegration = integration?.type === "OKTA" || integration?.type === "ENTRA";
                if (isIDPIntegration && integration.isEnabled) setIsIDPConnected(true);
            })
        }
    });

    const aggregatedData = useMemo(() => data?.applications.reduce((acc, curr) => {
        switch (curr.applicationType) {
            case 'REGULAR':
                acc.homegrownAppConnectors.push({...curr, name: curr.name.replaceAll(' ', '-')});
                break;
            case 'EXTENSION':
                if (!acc.employeesConnectorName) acc.employeesConnectorName = curr.name;
                break;
            case 'CODE_ASSISTANT':
                if (!acc.developersConnectorName) acc.developersConnectorName = curr.name;
                break;
        }
        return acc;
    }, {
        employeesConnectorName: "",
        developersConnectorName: "",
        homegrownAppConnectors: [] as Partial<Application>[],
    }),[data?.applications]);

    const {
        employeesConnectorName,
        developersConnectorName,
        homegrownAppConnectors,
    } = aggregatedData || {} as Connectors;

    const [lastHomegrownApp, setLastHomegrownApp] = useLocalStorage<string | undefined>('lastHomegrownApp');
    const [currentHGAID, setCurrentHGAID] = useLocalStorage<string | undefined>('currentHGAID');

    useEffect(() => {
        if (!homegrownAppConnectors?.length) return;
        if (!lastHomegrownApp) setLastHomegrownApp(homegrownAppConnectors[0].name);
        if (!currentHGAID) setCurrentHGAID(homegrownAppConnectors[0].id);
    }, [homegrownAppConnectors, lastHomegrownApp, setLastHomegrownApp, currentHGAID, setCurrentHGAID]);

    const value = {
        SCREEN_WIDTH_TO_MINIMIZE_GAP,
        isShowErrorForAllPage,
        setIsShowErrorForAllPage,

        isIDPConnected,
        isLoggingDisabled,

        violationsCount,
        setViolationsCount,
        promptsCount,
        setPromptsCount,
        installedExtensionsCount,
        setInstalledExtensionsCount,
        tableHeight,
        setTableHeight,

        lastHomegrownApp,
        setLastHomegrownApp,

        currentHGAID,
        setCurrentHGAID,

        employeesConnectorName,
        homegrownAppConnectors,
        developersConnectorName,
    }

    if (isLoading) return <PSLoadingScreen />

    return (
        <DashboardsContext.Provider value={value}>
            {children}
        </DashboardsContext.Provider>
    );
};

export const useDashboardsContext = () => {
    const context = React.useContext(DashboardsContext);
    if (!context) {
        throw new Error('useDashboardsContext must be used within a DashboardsContextProvider');
    }

    return context;
};