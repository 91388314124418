import { SENSITIVE_DATA_TYPES } from "../Common";

export type SensitiveDataPreset = {
    key: keyof typeof SENSITIVE_DATA_TYPES;
    categories: Partial<Array<keyof typeof CATEGORIES>>;
    description: string;
    examples: string[];
};

export const CATEGORIES = {
    PCI: "PCI",
    PII: "PII",
    GDPR: "GDPR",
    CCPA: "CCPA",
    PHI: "PHI",
    COPPA: "COPPA",
    IT: "IT"
}

export const CATEGORY_EXPLANATIONS: Record<keyof typeof CATEGORIES, { label: string, explanation: string }> = {
    PCI: {
        label: "Payment Card Industry",
        explanation: "Data security standard for organizations that handle branded credit cards from major card schemes. PCI compliance is essential for protecting sensitive payment card information and preventing fraud."
    },
    PII: {
        label: "Personally Identifiable Information",
        explanation: "Any data that can be used to identify a specific individual. This includes direct identifiers like names and ID numbers, as well as indirect identifiers that can be combined with other information to identify an individual."
    },
    GDPR: {
        label: "General Data Protection Regulation",
        explanation: "A comprehensive data protection law in the EU. It applies to the processing of personal data of individuals in the EU, regardless of where the processing takes place. GDPR aims to give individuals control over their personal data and to simplify the regulatory environment for international business."
    },
    CCPA: {
        label: "California Consumer Privacy Act",
        explanation: "A state statute intended to enhance privacy rights and consumer protection for residents of California, USA. It gives California residents the right to know about the personal information a business collects about them, and how it is used and shared."
    },
    PHI: {
        label: "Protected Health Information",
        explanation: "Any information about health status, provision of health care, or payment for health care that is created or collected by a Covered Entity (or a Business Associate of a Covered Entity), and can be linked to a specific individual. This is primarily related to the HIPAA Privacy Rule in the United States."
    },
    COPPA: {
        label: "Children's Online Privacy Protection Act",
        explanation: "A United States federal law designed to protect the privacy of children under 13 years of age. It applies to the online collection of personal information by persons or entities under U.S. jurisdiction from children under 13 years of age."
    },
    IT: {
        label: "Information Technology",
        explanation: "In this context, IT refers to sensitive data types that are specifically related to information technology systems and infrastructure. This includes things like API keys, server identifiers, and other technical data that could be used to gain unauthorized access to IT systems."
    }
};

export const SENSITIVE_DATA_PRESETS: Array<SensitiveDataPreset> = [
    {
        key: "PASSWORD_RE",
        categories: ["PCI", "PII", "GDPR", "CCPA", "PHI", "COPPA"],
        description: "Patterns that match common password formats",
        examples: ["My password is Test12!"]
    },
    {
        key: "CREDIT_CARD",
        categories: ["PCI", "PII", "GDPR", "CCPA"],
        description: "Valid credit card numbers from major providers",
        examples: ["My credit card: 5019717010103742", "My credit card: 122000000000003"]
    },
    {
        key: "UNVERIFIED_CREDIT_CARD",
        categories: ["PCI", "PII", "GDPR", "CCPA"],
        description: "Numbers that match credit card formats but haven't been verified",
        examples: ["My credit card number is 36168002586008", "My credit card number is 5599 9999 9999 9999"]
    },
    {
        key: "CRYPTO",
        categories: ["PCI", "PII", "GDPR"],
        description: "Cryptocurrency wallet addresses for various cryptocurrencies",
        examples: ["16Yeky6GMjeNkAiNcBY7ZhrLoMSgg1BoyZ", "bc1p5d7rjq7g6rdk2yhzks9smlaqtedr4dekq08ge8ztwac72sfr9rusxg3297"]
    },
    {
        key: "CVV_CVC",
        categories: ["PCI"],
        description: "3 or 4 digit security codes found on credit cards",
        examples: ["My credit card is 36168002586008, 123"]
    },
    {
        key: "EMAIL_ADDRESS",
        categories: ["PII", "GDPR", "CCPA", "PHI", "COPPA"],
        description: "Valid email addresses",
        examples: ["My email address is john@smith.com", "Email the following: john@acme.com, jim123@songs.co.uk"]
    },
    {
        key: "STREET_BUILDING",
        categories: ["PII", "GDPR", "CCPA", "PHI", "COPPA"],
        description: "Patterns that match postal address formats",
        examples: ["I live in 3180 18th St", "I live in 123 Main Street", "I live in 5 Hollywood Boulevard", "Send it to 5 Abbey Road"]
    },
    {
        key: "AU_ACN",
        categories: ["PII", "GDPR"],
        description: "Australian Company Numbers (9 digit numbers)",
        examples: ["My company number 006249976", "My company number 005 499 981"]
    },
    {
        key: "IL_ID_NUMBER",
        categories: ["PII", "GDPR"],
        description: "Israeli identification numbers (9 digits)",
        examples: ["My id is 231740705", "My id is 339677395"]
    },
    {
        key: "IL_PASSPORT_RE",
        categories: ["PII", "GDPR"],
        description: "Patterns matching Israeli passport numbers",
        examples: ["My Israeli passport number is 12345678"]
    },
    {
        key: "IBAN_CODE",
        categories: ["PII", "GDPR"],
        description: "International Bank Account Numbers used for international transfers",
        examples: ["list of ibans: AL47212110090000000235698741", "this is an iban VG96 VPVG 0000 0123 4567 8901 X in a sentence"]
    },
    {
        key: "IP_ADDRESS",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Internet Protocol addresses (both IPv4 and IPv6)",
        examples: ["684D:1111:222:3333:4444:5555:6:77", "192.168.0.1"]
    },
    {
        key: "PUBLIC_IP_ADDRESS",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Public IPv4 Internet Protocol addresses",
        examples: ["4.4.4.4"]
    },
    {
        key: "VIN",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Vehicle Identification Number",
        examples: ["My VIN is MTMA2CCK2FZWV2BJV"]
    },
    {
        key: "MEDICAL_LICENSE",
        categories: ["PII", "GDPR", "CCPA", "PHI"],
        description: "Medical practitioner license numbers",
        examples: ["My certificate number is: BB1388568", "My medical license number is K92993548"]
    },
    {
        key: "UK_NHS",
        categories: ["PII", "GDPR", "PHI"],
        description: "UK National Health Service numbers (10 digit numbers)",
        examples: ["My nhs is 401-023-2137", "My nhs is 221 395 1837"]
    },
    {
        key: "PHONE_NUMBER",
        categories: ["PII", "GDPR", "CCPA", "PHI"],
        description: "Telephone numbers in various formats",
        examples: ["My US number is (415) 555-0132, and my international one is +1 415 555 0132", "My number is 03-1625411"]
    },
    {
        key: "US_BANK_NUMBER",
        categories: ["PII", "GDPR", "CCPA", "PHI"],
        description: "US bank account numbers",
        examples: ["My bank number is 945456787654"]
    },
    {
        key: "DUTCH_BANK_ACCOUNT_NUMBER",
        categories: ["PII", "GDPR", "CCPA", "PHI"],
        description: "Dutch bank account numbers (IBAN format)",
        examples: ["My bank number 0417164300"]
    },
    {
        key: "US_DRIVER_LICENSE",
        categories: ["PII", "GDPR", "CCPA"],
        description: "US driver's license numbers (format varies by state)",
        examples: ["John’s drivers license is AC432223", "My drivers license is H12234567"]
    },
    {
        key: "US_ITIN",
        categories: ["PII", "GDPR", "CCPA"],
        description: "US Individual Taxpayer Identification Numbers (9 digits)",
        examples: ["My tax number is 911701234", "My tax number is 911-701234 91170-1234"]
    },
    {
        key: "US_PASSPORT",
        categories: ["PII", "GDPR", "CCPA"],
        description: "US passport numbers (9 digits)",
        examples: ["My travel document is A12803456", "My travel passport is Z12803456"]
    },
    {
        key: "US_SSN",
        categories: ["PII", "GDPR", "CCPA", "PHI"],
        description: "US Social Security Numbers (9 digits)",
        examples: ["My social security number 078 05 1123", "My social security number 078-05-1121"]
    },
    {
        key: "SG_NRIC_FIN",
        categories: ["PII", "GDPR"],
        description: "Singapore National Registration Identity Card numbers",
        examples: ["NRIC S2740116C was processed"]
    },
    {
        key: "PERSON",
        categories: ["PII", "GDPR", "CCPA", "PHI", "COPPA"],
        description: "Personal names of individuals",
        examples: ["My name is John"]
    },
    {
        key: "CITY",
        categories: ["PII", "GDPR", "CCPA", "PHI", "COPPA"],
        description: "Cities around the world",
        examples: ["John lives in Tel-Aviv", "I love Paris"]
    },
    {
        key: "ES_NIF",
        categories: ["PII", "GDPR"],
        description: "Spanish tax identification numbers",
        examples: ["My tax number 55555555K", "My tax number 1111111-G"]
    },
    {
        key: "AU_ABN",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Australian Business Numbers (11 digits)",
        examples: ["My business number 51824753556", "My business number 51 824 753 556"]
    },
    {
        key: "AU_TFN",
        categories: ["PII"],
        description: "Australian Tax File Numbers (8 or 9 digits)",
        examples: ["My tax number 876543210", "My tax number 876 543 210"]
    },
    {
        key: "AU_MEDICARE",
        categories: ["PII", "PHI"],
        description: "Australian Medicare numbers (10 or 11 digits)",
        examples: ["Medicare number: 2123456701", "Medicare number: 2123 45670 1"]
    },
    {
        key: "IT_DRIVER_LICENSE",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Italian driver's license numbers",
        examples: ["licenza AA0123456B"]
    },
    {
        key: "IT_VAT_CODE",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Italian VAT identification numbers",
        examples: ["partita iva 01333550323"]
    },
    {
        key: "IT_PASSPORT",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Italian passport numbers",
        examples: ["passaporto AA1234567", "passaporto aa7654321"]
    },
    {
        key: "IT_IDENTITY_CARD",
        categories: ["PII", "GDPR"],
        description: "Italian identity card numbers",
        examples: ["documento AA1234567,  aa 1234568"]
    },
    {
        key: "IT_FISCAL_CODE",
        categories: ["PII", "GDPR"],
        description: "Italian fiscal codes (16 characters)",
        examples: ["My fiscal code: AAAAAA00B11C333Y"]
    },
    {
        key: "URL",
        categories: ["PHI"],
        description: "Web addresses (Uniform Resource Locators)",
        examples: ["http://www.prompt.security", "https://www.google.com/"]
    },
    {
        key: "AWS_ARN_RE",
        categories: ["IT"],
        description: "Amazon Web Services (AWS) Amazon Resource Names",
        examples: ["arn:aws:s3:::my_corporate_bucket", "arn:aws:iam::123456789012:user/Development/product_1234"]
    },
    {
        key: "UUID_RE",
        categories: ["IT"],
        description: "Universally Unique Identifiers (UUIDs)",
        examples: ["550e8400-e29b-41d4-a716-446655440000"]
    },
    {
        key: "BASE64_RE",
        categories: ["IT"],
        description: "Base64 encoded data",
        examples: ["cHJvbXB0IHJ1bGVzIGdlbmFpIHNlY3VyaXR5", "UHJvbXB0IFNlY3VyaXR5IHdvcmxkIGxlYWRpbmcgZ2VuQUkgc2VjdXJpdHku"]
    },
    {
        key: "ORGANIZATION",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Names of companies, institutions, or other organizations",
        examples: ["I work at Acme", "I retired from Acme corp."]
    },
    {
        key: "SOUTH_KOREA_ID_NUMBER",
        categories: ["PII"],
        description: "Korean Resident Registration Numbers",
        examples: ["My ID number is 840911- 4567658"]
    },
    {
        key: "UAE_IDENTITY_CARD_NUMBER",
        categories: ["PII", "GDPR", "CCPA"],
        description: "UAE ID Card Number",
        examples: ["My id is 784-1234-1234567-1"]
    },
    {
        key: "BR_CPF_NUMBER",
        categories: ["PII", "GDPR", "CCPA"],
        description: "Brazil CPF Number",
        examples: ["390.533.447-05"]
    },
    {
        key: "ABA_ROUTING_NUMBER",
        categories: ["PII", "GDPR", "CCPA"],
        description: "ABA Routing Number",
        examples: ["My aba number is 2113-7054-5"]
    },
    {
        key: "SWIFT_CODE",
        categories: ["PII", "GDPR"],
        description: "Swift codes used for international transfers",
        examples: ["My swift code is YNBIGB65ZT4"]
    },
    {
        key: "IL_BANK_NUMBER",
        categories: ["PII", "GDPR", "CCPA", "PHI"],
        description: "Israeli bank account numbers",
        examples: ["My bank number is 12-345-67890123"]
    },
    {
        key: "UAE_PASSPORT",
        categories: ["PII", "GDPR"],
        description: "Patterns matching UAE passport numbers",
        examples: ["My passport number is zk8k81401"]
    },
    {
        key: "CANADA_PASSPORT",
        categories: ["PII", "GDPR"],
        description: "Patterns matching Canada passport numbers",
        examples: ["My passport is ZE001355"]
    },
    {
        key: "CANADA_BANK_ACCOUNT_NUMBER",
        categories: ["PII", "GDPR"],
        description: "Patterns matching Canada bank account numbers",
        examples: ["My bank account number: 1234567", "My bank account number: 123456789012"]
    },
    {
        key: "CANADA_HEALTH_SERVICE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Canada health service numbers (NOT to be confused with PHIN)",
        examples: ["My health service number is 1234567890"]
    },
    {
        key: "CANADA_SOCIAL_INSURANCE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Canada social insurance numbers - sin (NOT to be confused with PHIN)",
        examples: ["My insurance number is 046 454 286"]
    },
    {
        key: "CANADA_PERSONAL_HEALTH_ID_NUMBER_PHIN",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Canada personal health identification number (PHIN)",
        examples: ["My phin is 123456789"]
    },
    {
        key: "CANADA_DRIVERS_LICENSE_NUMBER",
        categories: ["PII", "GDPR"],
        description: "Patterns matching Canada driver's license numbers (from all counties)",
        examples: ["My drivers license 134711-320", "My drivers license 12345", "My drivers license 123456789", "My drivers license 1234567", "My drivers license PU-BL-IJ-Q008NH",
                   "My drivers license PUBLIJQ008NH", "My drivers license N123456789", "My drivers license PUBLI020220005", "My drivers license PUBLI-141123456",
                   "My drivers license D6101-40709-50228", "My drivers license 123456", "My drivers license L153117127408", "My drivers license 12345678"]
    },
    {
        key: "GERMANY_DRIVERS_LICENSE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching German driver's license number",
        examples: ["My drivers license Z021AB37X13", "My drivers license B072RRE2I51"]
    },
    {
        key: "GERMANY_ID_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching German identification number",
        examples: ["My identity number is LZ6311T47", "My identity number is 1220001318", "My identity number is T22000129"]
    },
    {
        key: "GERMANY_PASSPORT_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching German passport number",
        examples: ["My passport number C01XM2586", "My passport number C01X0006H", "My passport number C3JJ4789L"]
    },
    {
        key: "GERMANY_TAX_ID_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching German tax identification number",
        examples: ["Who is the person with tax number 12 345 678 901"]
    },
    {
        key: "GERMANY_VAT_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching German value added tax number",
        examples: ["My vat number is DE123456789"]
    },
    {
        key: "JAPAN_BANK_ACCOUNT_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Japanese bank account numbers",
        examples: ["What is the balance in bank account number: 2554345"]
    },
    {
        key: "JAPAN_DRIVER_LICENSE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Japanese drivers license numbers",
        examples: ["My drivers license is 301902756190"]
    },
    {
        key: "JAPAN_MY_NUMBER_CORPORATE",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Japanese MY Number Corporate numbers",
        examples: ["My personal number is 4010001175992"]
    },
    {
        key: "JAPAN_MY_NUMBER_PERSONAL",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Japanese MY Number Personal numbers",
        examples: ["My personal number: 1234-5678-9012"]
    },
    {
        key: "JAPAN_PASSPORT_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Japanese passport numbers",
        examples: ["Who is passport number: FG4567890"]
    },
    {
        key: "JAPAN_RESIDENCE_CARD_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Japanese residence card numbers",
        examples: ["My residence number FG56234567RT"]
    },
    {
        key: "JAPAN_RESIDENT_REGISTRATION_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Japanese resident registration numbers",
        examples: ["My resident number is 37799136102"]
    },
    {
        key: "JAPAN_SOCIAL_INSURANCE_NUMBER_SIN",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Japanese social insurance numbers (sin)",
        examples: ["My social insurance number is 1234-567890", "Who is behined insurance number 123456789012"]
    },
    {
        key: "SINGAPORE_DRIVER_LICENSE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Singaporian driver's license number",
        examples: ["My driver license number S9926666F"]
    },
    {
        key: "SINGAPORE_PASSPORT_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching Singaporian passport number",
        examples: ["My passport number is E0099960A"]
    },
    {
        key: "SOUTH_KOREA_ALIEN_REGISTERATION_NUMBER",
        categories: ["PII", "GDPR" ],
        description: "Patterns matching South Korean alien registeration number",
        examples: ["Who has an alien number: 121209-5234567"]
    },
    {
        key: "SOUTH_KOREA_CREDIT_CARD_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching South Korean credit card number",
        examples: ["My credit number is 9876-5432-1234-5678", "What is the currency for card number 3512-3456-7890-1234"]
    },
    {
        key: "SOUTH_KOREA_DOMESITC_RESIDENCE_REPORT_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching South Korean domestic residence report number",
        examples: ["Who is residence number 121209-5123477"]
    },
    {
        key: "SOUTH_KOREA_DRIVER_LICENSE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching South Korean driver's license number",
        examples: ["My drivers license is 12-084384-30", "My drivers license number is 21-19-174133-01"]
    },
    {
        key: "SOUTH_KOREA_HEALTH_INSURANCE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching South Korean health insurance number",
        examples: ["My insurance number is 710608-12345"]
    },
    {
        key: "SOUTH_KOREA_PASSPORT_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching South Korean passport number",
        examples: ["Who is passport number M123A4567", "Who is passport number M35772699"]
    },
    {
        key: "SOUTH_KOREA_PASSPORT_MRZ_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching South Korean passport with MRZ (machine reading zone) number",
        examples: ["My passport is: PMKORLEE<<SUYEON<<<<<<<<<<<<<<<M706890985KOR8507022F24041522154710V17627884"]
    },
    {
        key: "SOUTH_KOREA_RESIDENT_REGISTRATION_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching South Korean resident registration number",
        examples: ["My resident number is 610702-1234567"]
    },
    {
        key: "UK_DRIVER_LICENSE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching UK drivers license number",
        examples: ["Who has driver license: MORGA753116SM9IJ35"]
    },
    {
        key: "UK_ELECTORAL_ROLL_NUMBER",
        categories: ["PII", "GDPR"],
        description: "Patterns matching UK electoral roll number",
        examples: ["My electoral number is: NR2345"]
    },
    {
        key: "UK_NATIONAL_INSURANCE_NUMBER_NINO",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching UK national insurance number (NINO)",
        examples: ["Who is the person with nino QQ123456A"]
    },
    {
        key: "UK_UNIQUE_TAXPAYER_REFERENCE_NUMBER",
        categories: ["PII", "GDPR", "PHI" ],
        description: "Patterns matching UK unique taxpayer reference number",
        examples: ["My tax number is 9876543210"]
    }
];



type CategorizedSensitiveData = {
    [K in keyof typeof CATEGORIES]: SensitiveDataPreset[];
};

export const categorizeSensitiveData = (presets: typeof SENSITIVE_DATA_PRESETS): CategorizedSensitiveData => {
    const categorized = Object.keys(CATEGORIES).reduce((acc, category) => {
        acc[category as keyof typeof CATEGORIES] = [];
        return acc;
    }, {} as CategorizedSensitiveData);

    presets.forEach(preset => {
        preset.categories.forEach(category => {
            if (category && category in CATEGORIES) {
                categorized[category].push(preset);
            }
        });
    });

    return categorized;
}

export const categorizedSensitiveData = categorizeSensitiveData(SENSITIVE_DATA_PRESETS);