/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {TOverviewVariants} from "./types";

export const PSOverviewStyle = (variant: TOverviewVariants) => ({
    self: (minWidth: number | undefined, maxWidth: number | undefined) => css`
        ${minWidth ? css`min-width: ${minWidth}px;` : ''}
        ${maxWidth ? css`max-width: ${maxWidth}px;` : ''}
        width: 100%;
        border: ${variant === "dark" ? '' : '1px solid var(--color-black-30)'};
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        background: ${variant === "dark" ? 'var(--color-gradient-purple)' : 'var(--color-white)'};
        min-height: 155px;
        container-type: inline-size; 
    `,
    glow: css`
        z-index: unset;
        position: absolute;
        right: -18%;
        top: 20%;
        background: ${variant === "dark" ? 'var(--color-white-15)' : '#ba95e31f'}; // TODO - add opacity to design system somehow.
        -webkit-backdrop-filter: blur(37px);
        -ms-filter: blur(37px);
        filter: blur(37px);
    `,
    topContainer: css`
        min-height: 115px;
        width: 100%; 
        border-bottom: 1px solid ${variant === "dark" ? 'var(--color-white-15)' : 'var(--color-black-30)'}; // 
        display: flex;
        align-items: center;
    `,
    contentWrapper: (maxContentWidth: number | undefined) => css`
        display: flex;
        justify-content: space-between;
        padding: 0 25px 0 30px;
        width: 100%;
        gap: 30px;
        ${maxContentWidth ? css`max-width: ${maxContentWidth}px;` : ''}
    `,
    contentTextContainer: css`
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 70px;
        gap: 5px;
    `,
    textContent: css`
        color: ${variant === "dark" ? 'var(--color-white)' : 'var(--color-black)'};
        font-weight: ${variant === "dark" ? 700 : 400};
        line-height: unset;
        margin-bottom: 5px;
        z-index: 5;
    `,
    numberContent: css`
        color: ${variant === "dark" ? 'var(--color-white)' : 'var(--color-black)'};
        font-weight: 700;
        font-size: 45px;
        line-height: 40px;
        z-index: 5;
    `,
    iconWrapper: css`
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    iconContainer: css`
        width: 70px;
        height: 70px;
        border-radius: 100%;
        border: ${variant === "dark" ? '' : '2px solid var(--color-purple-50)'};
        background: var(--color-white);
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    badgeContainer: css`
        align-self: flex-end;
        z-index: 10;
        margin-left: 5px;
    `,
    skeletonContainer: css`
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
    `,
    skeleton: css`
        background: ${variant === "dark" ? 'var(--color-white)' : 'var(--color-black-30)'};
    `,
    numberSkeletonContainer: css`
        width: 100%;
        max-width: 240px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    numberAndBadgeContainer: css`
        min-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    numberAndPopperContainer: css`
        position: relative; 
        width: 100%;
    `,
    popperWrapperCss: css`
        width: 100%; 
        height: 180%;  
        position: absolute; 
        top: -70%; 
        left: -10%;
        border: 10px solid transparent; 
        border-top: 0; 
        box-sizing: content-box; 
    `,
    footerContainer: css`
        z-index: 10;
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 25px 0 30px;
    `,
    errorContainer: css`
        border: 1px solid var(--color-black-30); 
        border-radius: 15px;
        background: var(--color-white);
    `,
});